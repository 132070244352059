// src/config.ts

const config = {
  // API Configuration
  apiUrl: process.env.NODE_ENV === 'production'
    ? 'https://vm-back.onrender.com'
    : 'http://localhost:3001',

  // Solana Configuration
  rpcUrl: 'https://quaint-purple-cloud.solana-mainnet.quiknode.pro/e7c5e1a9937c7a830bf6dad5ef4cd71ef9b39a61',
  platformWallet: 'EhEToRx3tWg5fNv9gEa7GSMeuXs2UstmzjaJTRxJ1zQV',
  connection: {
    commitment: 'confirmed' as const,
    confirmTransactionInitialTimeout: 60000,
  },
  transaction: {
    maxRetries: 3,
    skipPreflight: false,
    preflightCommitment: 'confirmed' as const,
  }
};

export default config;