// src/components/WalletConnection.tsx
import React, { FC, useMemo } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const WalletConnection: FC = () => {
  const { publicKey } = useWallet();

  return (
    <div className="flex flex-col items-center space-y-2">
      <WalletMultiButton className="btn btn-primary" />
    </div>
  );
};

export default WalletConnection;
