import React, { useState } from 'react';
import { calculateFee } from '../utils/fees.ts';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Buffer } from 'buffer';
import config from '../config.ts';

// In TradeForm.tsx
interface TradeFormProps {
  onSubmit: (
    coinAddress: string, 
    duration: number, 
    volume: number, 
    userWallet: string, 
    transactionSignature: string
  ) => Promise<any>;
  isProcessing?: boolean;
}

const TradeForm: React.FC<TradeFormProps> = ({ onSubmit }) => {
  const { publicKey, sendTransaction } = useWallet();
  const [coinAddress, setCoinAddress] = useState('');
  const [duration, setDuration] = useState<number>(1);
  const [volume, setVolume] = useState<number>(1000);
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const PLATFORM_WALLET = new PublicKey(config.platformWallet);
  
  const validateCoinAddress = (address: string) => {
    const base58Regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
    return base58Regex.test(address);
  };

  const fee = calculateFee(volume, duration);

  const createPaymentTransaction = async (
    connection: Connection,
    fromPublicKey: PublicKey,
    feeInSOL: number
  ) => {
    const feeInLamports = feeInSOL * LAMPORTS_PER_SOL;

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: fromPublicKey,
        toPubkey: PLATFORM_WALLET,
        lamports: Math.floor(feeInLamports)
      })
    );

    try {
      const { blockhash } = await connection.getLatestBlockhash('confirmed');
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = fromPublicKey;
      return transaction;
    } catch (error) {
      console.error('Error getting recent blockhash:', error);
      throw new Error('Failed to create transaction. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: {[key: string]: string} = {};

    if (!publicKey) {
      newErrors.wallet = 'Please connect your wallet first';
    }

    if (duration < 1 || duration > 24) {
      newErrors.duration = 'Duration must be between 1 and 24 hours';
    }

    if (volume < 1000 || volume > 100000) {
      newErrors.volume = 'Volume must be between 1,000 and 100,000 USD';
    }

    if (!validateCoinAddress(coinAddress)) {
      newErrors.coinAddress = 'Please enter a valid Solana token address';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      try {
        console.log('Creating Solana connection...');
        const connection = new Connection(config.rpcUrl, config.connection);

        // Test connection
        try {
          const blockHeight = await connection.getBlockHeight();
          console.log('Connection successful, block height:', blockHeight);
        } catch (connError) {
          console.error('Connection test failed:', connError);
          throw new Error('Failed to connect to Solana network. Please try again.');
        }

        console.log('Creating transaction...');
        const transaction = await createPaymentTransaction(connection, publicKey!, fee);

        console.log('Sending transaction...');
        const signature = await sendTransaction(transaction, connection, config.transaction);
        console.log('Transaction sent, signature:', signature);

        // Wait for confirmation
        try {
          const confirmation = await connection.confirmTransaction(signature, 'confirmed');
          if (confirmation.value.err) {
            throw new Error('Transaction failed to confirm');
          }
          console.log('Transaction confirmed:', signature);
        } catch (confirmError) {
          console.error('Confirmation error:', confirmError);
          throw new Error('Failed to confirm transaction. Please check your wallet for the status.');
        }

        // Submit the trade request with the transaction signature
        const response = await onSubmit(
          coinAddress,
          duration,
          volume,
          publicKey!.toBase58(),
          signature
        );

        setOrderNumber(response.requestId);
        setShowSuccess(true);
      } catch (error) {
        console.error('Full error details:', error);
        const errorMsg = error instanceof Error ? error.message : 'Failed to submit trade request';
        setErrorMessage(errorMsg);
        setShowErrorPopup(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDurationBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value < 1 || value > 24) {
      setDuration(Math.min(Math.max(value, 1), 24));
      setErrors(prev => ({ ...prev, duration: 'Duration must be between 1 and 24 hours' }));
    } else {
      setErrors(prev => ({ ...prev, duration: '' }));
    }
  };

  const handleVolumeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value < 1000 || value > 100000) {
      setVolume(Math.min(Math.max(value, 1000), 100000));
      setErrors(prev => ({ ...prev, volume: 'Volume must be between 1,000 and 100,000 USD' }));
    } else {
      setErrors(prev => ({ ...prev, volume: '' }));
    }
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setShowDisclaimer(true);
  };

  // Your existing JSX remains exactly the same
  return (
    <div className="flex flex-col w-full max-w-md">
      <form onSubmit={handleSubmit} className="flex flex-col w-full space-y-4 p-4 bg-base-200 rounded shadow">
        <div>
          <label className="label">
            <span className="label-text">Coin Address</span>
          </label>
          <input
            type="text"
            placeholder="Enter coin address"
            className={`input input-bordered w-full ${errors.coinAddress ? 'input-error' : ''}`}
            value={coinAddress}
            onChange={(e) => setCoinAddress(e.target.value)}
            required
          />
          {errors.coinAddress && <span className="text-error text-sm mt-1">{errors.coinAddress}</span>}
        </div>

        <div>
          <label className="label">
            <span className="label-text">Duration (Hours) - min 1, max 24</span>
          </label>
          <input
            type="number"
            min={1}
            max={24}
            placeholder="e.g. 1"
            className={`input input-bordered w-full ${errors.duration ? 'input-error' : ''}`}
            value={duration}
            onChange={(e) => setDuration(Number(e.target.value))}
            onBlur={handleDurationBlur}
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            required
          />
          {errors.duration && <span className="text-error text-sm mt-1">{errors.duration}</span>}
        </div>

        <div>
          <label className="label">
            <span className="label-text">Volume (USD equivalent) - min 1,000, max 100,000</span>
          </label>
          <input
            type="number"
            min={1000}
            max={100000}
            step={1000}
            placeholder="e.g. 1000"
            className={`input input-bordered w-full ${errors.volume ? 'input-error' : ''}`}
            value={volume}
            onChange={(e) => setVolume(Number(e.target.value))}
            onBlur={handleVolumeBlur}
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            required
          />
          {errors.volume && <span className="text-error text-sm mt-1">{errors.volume}</span>}
        </div>

        <div className="text-sm">
          Estimated Fee: <span className="font-bold">{fee.toFixed(2)} SOL</span>
        </div>

        <button 
          type="submit" 
          className="btn btn-primary w-full" 
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span className="loading loading-spinner"></span>
              Processing...
            </>
          ) : (
            'Start Volume Bot'
          )}
        </button>
      </form>

      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-base-200 p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-lg font-bold mb-4 text-green-600">Order Successful!</h3>
            <p className="mb-4">Your order has been submitted successfully.</p>
            <p className="mb-4 font-mono bg-base-100 p-2 rounded">
              Order Number: {orderNumber}
            </p>
            <p className="mb-4 text-sm text-gray-600">
              Please save this order number for future reference.
            </p>
            <button 
              onClick={handleSuccessClose}
              className="btn btn-primary w-full"
            >
              Continue
            </button>
          </div>
        </div>
      )}

      {showDisclaimer && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-base-200 p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-lg font-bold mb-4">Important Disclaimer</h3>
            <p className="mb-4">
              By using this service, you acknowledge and accept all associated risks, including but not limited to changes in market conditions, potential loss of funds, or any unintended outcomes resulting from the volume mixing activities. All sales are final and non-refundable. We provide these services as-is, without any guarantees, warranties, or promises of future financial gain or market impact. Furthermore, we are not liable for any form of misconduct, technical failures, unauthorized access, or user error that may occur. It is your responsibility to understand and comply with all relevant laws, regulations, and policies applicable to your jurisdiction. For additional inquiries or assistance, please reach out to{' '}
              <a href="mailto:volumemixer@proton.me" className="text-blue-500 hover:text-blue-600">
                volumemixer@proton.me
              </a>.
            </p>
            <button 
              onClick={() => setShowDisclaimer(false)}
              className="btn btn-primary w-full"
            >
              I Understand
            </button>
          </div>
        </div>
      )}

      {showErrorPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-base-200 p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-lg font-bold mb-4 text-red-600">Order Failed</h3>
            <p className="mb-4">There was an error submitting your order:</p>
            <p className="mb-4 font-mono bg-base-100 p-2 rounded text-red-600">
              {errorMessage}
            </p>
            <button
              onClick={() => setShowErrorPopup(false)}
              className="btn btn-primary w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <footer className="text-center text-sm text-gray-500 mt-4 p-2">
        For additional services or help with your order please reach out to{' '}
        <a href="mailto:volumemixer@proton.me" className="text-blue-500 hover:text-blue-600">
          volumemixer@proton.me
        </a>
      </footer>
    </div>
  );
};

export default TradeForm;