// src/utils/fees.ts

export function calculateFee(volume: number, duration: number): number {
    const baseFee = 0.4; // 0.4 SOL as a base
    const volumeFactor = 0.05; // 0.05 SOL per $1,000
    const timeFactor = 0.1; // 0.01 SOL per hour

    const volumeUnits = volume / 1000;
    const volumeFee = volumeUnits * volumeFactor;
    const timeFee = duration * timeFactor;

    return baseFee + volumeFee + timeFee;
}
