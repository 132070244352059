// src/App.tsx
import React, { useState } from 'react';
import WalletConnection from './components/WalletConnection.tsx';
import TradeForm from './components/TradeForm.tsx';
import config from './config.ts';

function App() {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleTradeSubmit = async (
    coinAddress: string, 
    duration: number, 
    volume: number, 
    userWallet: string, 
    transactionSignature: string
  ) => {
    setIsProcessing(true);
    try {
      const requestData = {
        coinAddress,
        duration,
        volume,
        userWallet,
        transactionSignature
      };

      console.log('Submitting trade request:', {
        transactionSignature,
        coinAddress,
        duration,
        volume,
        userWallet
      });

      const response = await fetch(`${config.apiUrl}/api/trade-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Trade request failed:', {
          status: response.status,
          statusText: response.statusText,
          error: errorData
        });
        throw new Error(errorData || `Failed to submit trade request (${response.status})`);
      }

      const responseData = await response.json();
      console.log('Trade request successful:', responseData);
      return responseData;
    } catch (error) {
      console.error('Error processing trade request:', error);
      throw error instanceof Error 
        ? error 
        : new Error('An unexpected error occurred while processing your request');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-base-100 text-base-content">
      <div className="navbar bg-base-200">
        <a className="btn btn-ghost normal-case text-xl">VolumeMixer</a>
        <div className="flex-1"></div>
        <WalletConnection />
      </div>

      <div className="flex justify-center mt-10">
        <TradeForm 
          onSubmit={handleTradeSubmit} 
          isProcessing={isProcessing}
        />
      </div>

      {/* You might want to add a global error boundary here */}
      <footer className="fixed bottom-0 w-full p-4 text-center text-sm text-gray-500 bg-base-200">
        <p>
          Need help? Contact us at{' '}
          <a 
            href="mailto:volumemixer@proton.me" 
            className="text-blue-500 hover:text-blue-600"
          >
            volumemixer@proton.me
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;